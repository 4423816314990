import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'
import Icon from 'components/shared/icon'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import arrowRight from 'assets/icons/arrow-right-big.svg'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightgray};
  padding: 35px 0 40px;
  margin: 60px 0 80px;

  p:first-of-type {
    font-family: Merriweather;
  }

  ${media.lg.min} {
    padding: 76px 0 91px;
    margin: 100px 0;
  }
`

const TilesWrapper = styled.div`
  display: grid;
  gap: 35px;

  ${media.lg.min} {
    grid-template-columns: repeat(3, min(calc(33% - 10px), 336px));
    justify-content: center;
    gap: 20px;
  }
`

const SingleTileWrapper = styled.div`
  img {
    aspect-ratio: 1.52;
  }

  ${media.lg.min} {
    img {
      aspect-ratio: 1;
    }
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
`

const TileFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  ${media.lg.min} {
    margin-top: 23px;

    span {
      transition: 0.3s;
      opacity: 0;
    }
  }
`

type Props = {
  heading: string
  tiles: Array<{
    title: string
    slug: string
    image: {
      src: ImageDataLike
      alt: string
    }
  }>
}

const SeeAlso: React.FC<Props> = ({ heading, tiles }) => {
  const { lg } = useBreakpoint()

  return (
    <Wrapper>
      <Container>
        <Heading
          as="p"
          size={lg ? 34 : 26}
          sizeDiff={0}
          align="center"
          margin={lg ? '47px' : '40px'}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <TilesWrapper>
          {tiles?.map(({ title, slug, image }) => (
            <SingleTileWrapper as={Link} to={`/drzwi-przesuwne/${slug}/`}>
              <LazyImage src={image?.src} alt={image?.alt} />
              <TileFooter>
                <Text
                  size={lg ? 24 : 20}
                  weight={700}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                <Icon src={arrowRight} width={35} height={23} />
              </TileFooter>
            </SingleTileWrapper>
          ))}
        </TilesWrapper>
      </Container>
    </Wrapper>
  )
}

export default SeeAlso
