import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import Icon from 'components/shared/icon'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import arrowIcon from 'assets/icons/arrow-dropdown.svg'

const StyledContainer = styled(Container)<{
  spacing?: boolean
}>`
  display: flex;
  padding: 40px 46px 30px 36px;

  ${media.lg.min} {
    padding: 40px 30px 60px 30px;
  }

  ${({ spacing }) =>
    // add more padding to include navbar height
    spacing &&
    css`
      padding: 116px 36px 40px 36px;

      ${media.lg.min} {
        padding: 140px 30px 45px 30px;
      }
    `}
`

const CrumbWrapper = styled.div`
  display: flex;
`

const StyledSeparator = styled.span`
  margin: 0 10px;
`

type Crumb = {
  name: string
  to?: string
}

type Props = {
  crumbs: Crumb[]
  spacing?: boolean
}

const Breadcrumbs: React.FC<Props> = ({ crumbs, spacing }) => {
  const { sm } = useBreakpoint()

  const allCrumbs = [{ name: 'Strona główna', to: '/' }, ...crumbs].slice(
    sm ? 0 : -2
  )

  return (
    <StyledContainer spacing={spacing}>
      {allCrumbs.map(({ name, to }, index) => {
        const isFirst = index === 0
        const isLast = index === allCrumbs.length - 1
        const hasTo = !!to

        return (
          <CrumbWrapper key={`crumb-${index}`}>
            {hasTo ? (
              <Link to={isFirst ? '/' : `${to}/`}>
                <Text margin="0" weight={isLast ? 600 : 400}>
                  {name}
                </Text>
              </Link>
            ) : (
              <div>
                <Text margin="0" weight={isLast ? 600 : 400}>
                  {name}
                </Text>
              </div>
            )}

            {!isLast && (
              <StyledSeparator>
                <Icon width={7} src={arrowIcon} />
              </StyledSeparator>
            )}
          </CrumbWrapper>
        )
      })}
    </StyledContainer>
  )
}

export default Breadcrumbs
