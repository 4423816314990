import React from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

import Breadcrumbs from 'components/shared/breadcrumbs'

import ContactSectionDoor from 'components/contact-section-door'
import Accessories from 'components/pages/sliding-doors/accessories'

import Description from 'components/pages/door/description'
import SeeAlso from 'components/pages/door/see-also'
import Specs from 'components/pages/door/specs'
import Profiles from 'components/pages/door/profiles'
import Models from 'components/pages/door/models'
import Examples from 'components/pages/door/examples'

import { SlidingDoorPageQuery } from 'types/graphql'

const Drzwi: React.FC<PageProps<SlidingDoorPageQuery>> = ({ data }) => {
  const PAGE = data?.wpDoorsSingle?.drzwiPrzesuwne!
  const PAGE_SEO = data?.wpDoorsSingle?.seo!

  const OTHER_DOORS =
    data?.allWpDoorsSingle?.nodes?.map((door) => ({
      title: door?.title!,
      slug: door?.slug!,
      image: {
        src: door?.featuredImage?.node?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: door?.featuredImage?.node?.altText!,
      },
    })) || []

  const PROFILES =
    PAGE?.slidingDoorProfiles?.map((profile) => ({
      color: profile?.slidingDoorProfilesColor!,
      image: {
        src: profile?.slidingDoorProfilesImage?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: profile?.slidingDoorProfilesImage?.altText!,
      },
    })) || []

  const MODELS =
    PAGE?.slidingDoorModels?.map((cat) => ({
      category: cat?.slidingDoorModelsGroup!,
      description: cat?.slidingDoorModelsDescription!,
      types:
        cat?.slidingDoorModels?.map((type) => ({
          name: type?.slidingDoorModelsType!,
          image: {
            src: type?.slidingDoorModelsImage?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: type?.slidingDoorModelsImage?.altText!,
          },
        })) || [],
    })) || []

  const EXAMPLE_MODELS =
    PAGE?.slidingDoorExamples?.map((model) => ({
      image: {
        src: model?.slidingDoorExamplesImage?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: model?.slidingDoorExamplesImage?.altText!,
      },
      info: model?.slidingDoorExamplesInfo!,
    })) || []

  const SPECS = {
    technicalData:
      PAGE?.slidingDoorTechnicalData?.map((item) => ({
        parameter: item?.slidingDoorTechnicalDataParameter!,
        value: item?.slidingDoorTechnicalDataValue!,
      })) || [],
    accessories:
      PAGE?.slidingDoorAccessories?.map(
        (item) => item?.slidingDoorAccessory!
      ) || [],
    track: {
      headings:
        PAGE?.slidingDoorTrack?.slidingDoorTrackHeadings?.map(
          (heading) => heading?.slidingDoorTrackHeading!
        ) || [],
      items:
        PAGE?.slidingDoorTrack?.slidingDoorTrackItems?.map(
          (item) =>
            item?.slidingDoorTrackItem?.map(
              (elem) => elem?.slidingDoorTrackItemValue!
            ) || []
        ) || [],
      remarks: PAGE?.slidingDoorTrack?.slidingDoorTrackRemarks!,
    },
  }

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Breadcrumbs
        spacing
        crumbs={[
          { name: 'Drzwi przesuwne', to: '/drzwi-przesuwne' },
          {
            name: data?.wpDoorsSingle?.title!,
          },
        ]}
      />
      <Description
        heading={PAGE?.slidingDoorIntroHeading!}
        text={PAGE?.slidingDoorIntroDescription!}
        image={{
          src: PAGE?.slidingDoorIntroImage?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.slidingDoorIntroImage?.altText!,
        }}
      />
      <Profiles
        heading={PAGE?.slidingDoorProfilesHeading!}
        profiles={PROFILES}
      />
      <Models heading={PAGE?.slidingDoorModelsHeading!} models={MODELS} />
      <Examples
        heading={PAGE?.slidingDoorExamplesHeading!}
        examples={EXAMPLE_MODELS}
      />
      <Specs specs={SPECS} />
      <Accessories
        heading={PAGE?.slidingDoorExtrasHeading!}
        text={PAGE?.slidingDoorExtrasParagraph!}
      />
      <SeeAlso
        heading={PAGE?.slidingDoorOtherModelsHeading!}
        tiles={OTHER_DOORS}
      />
      <ContactSectionDoor
        title={PAGE?.slidingDoorContactHeading!}
        text={PAGE?.slidingDoorContactParagraph!}
        phone={PAGE?.slidingDoorContactPhone!}
        email={PAGE?.slidingDoorContactEmail!}
        initialFormMessage={PAGE?.slidingDoorContactInitialFormMessage!}
      />
    </Layout>
  )
}

export default Drzwi

export const query = graphql`
  query SlidingDoorPage($slug: String) {
    wpDoorsSingle(slug: { eq: $slug }) {
      seo {
        ...WpSEO
      }
      title
      slug
      drzwiPrzesuwne {
        slidingDoorIntroHeading
        slidingDoorIntroDescription
        slidingDoorIntroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1500)
            }
          }
        }
        slidingDoorProfilesHeading
        slidingDoorProfiles {
          slidingDoorProfilesColor
          slidingDoorProfilesImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 350)
              }
            }
          }
        }
        slidingDoorModelsHeading
        slidingDoorModels {
          slidingDoorModelsGroup
          slidingDoorModelsDescription
          slidingDoorModels {
            slidingDoorModelsType
            slidingDoorModelsImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 250)
                }
              }
            }
          }
        }
        slidingDoorExamplesHeading
        slidingDoorExamples {
          slidingDoorExamplesInfo
          slidingDoorExamplesImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
          }
        }

        slidingDoorTechnicalData {
          slidingDoorTechnicalDataParameter
          slidingDoorTechnicalDataValue
        }
        slidingDoorAccessories {
          slidingDoorAccessory
        }
        slidingDoorTrack {
          slidingDoorTrackRemarks
          slidingDoorTrackHeadings {
            slidingDoorTrackHeading
          }
          slidingDoorTrackItems {
            slidingDoorTrackItem {
              slidingDoorTrackItemValue
            }
          }
        }
        slidingDoorExtrasHeading
        slidingDoorExtrasParagraph
        slidingDoorOtherModelsHeading
        slidingDoorContactHeading
        slidingDoorContactParagraph
        slidingDoorContactPhone
        slidingDoorContactEmail
        slidingDoorContactInitialFormMessage
      }
    }
    allWpDoorsSingle(filter: { slug: { ne: $slug } }) {
      nodes {
        title
        slug
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
          }
        }
      }
    }
  }
`
