import React from 'react'

import SideBySide from 'components/shared/side-by-side'
import ContactInfo from 'components/pages/sliding-doors/contact-info'
import ContactForm from 'components/contact-form'

type Props = {
  title: string
  text: string
  phone: string
  email: string
  initialFormMessage: string
}

const ContactSectionDoor: React.FC<Props> = ({
  title,
  text,
  phone,
  email,
  initialFormMessage,
}) => {
  return (
    <SideBySide addMarginBottom>
      <ContactInfo title={title} text={text} phone={phone} email={email} />
      <ContactForm message={initialFormMessage} />
    </SideBySide>
  )
}

export default ContactSectionDoor
