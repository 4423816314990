import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'
import Icon from 'components/shared/icon'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import arrowIcon from 'assets/icons/arrow-back.svg'

import { ImageDataLike } from 'gatsby-plugin-image'

const StyledContainer = styled(Container)`
  ${media.lg.max} {
    padding: 0;
  }
`

const TextWrapper = styled.div`
  ${media.lg.max} {
    padding: 0 36px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 450px;

  ${media.lg.min} {
    height: 463px;
  }
`

const GoBackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  padding: 0 0 0 36px;

  span {
    margin-right: 20px;
  }

  ${media.lg.min} {
    margin-bottom: 45px;
    padding: 0;
  }
`

type Props = {
  heading: string
  text: string
  image: {
    src: ImageDataLike
    alt: string
  }
}

const Description: React.FC<Props> = ({ heading, text, image }) => {
  const { lg } = useBreakpoint()

  return (
    <StyledContainer>
      <GoBackButton onClick={() => navigate(-1)}>
        <Icon src={arrowIcon} />
        <Text weight={700} margin="0">
          Wróć
        </Text>
      </GoBackButton>
      <TextWrapper>
        <Heading
          as="h1"
          size={lg ? 34 : 22}
          sizeDiff={0}
          margin={lg ? '30px' : '25px'}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <Text
          size={lg ? 20 : 18}
          margin={lg ? '30px' : '40px'}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </TextWrapper>
      <ImageWrapper>
        <LazyImage src={image?.src} alt={image?.alt} fill />
      </ImageWrapper>
    </StyledContainer>
  )
}

export default Description
