import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { Heading, Text } from 'components/shared/typography'
import Container from 'components/shared/container'
import Button from 'components/shared/button'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

const StyledContainer = styled(Container)`
  ${media.lg.max} {
    padding: 0;
  }
`

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.honeydew};
  padding: 45px 36px 40px 36px;
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    flex-direction: row;
    justify-content: space-between;
    padding: 94px 84px;
  }
`

const TextColumn = styled.div``

const ButtonColumn = styled.div`
  display: flex;
  align-items: center;
`

type Props = {
  heading: string
  text: string
}

const Accessories: React.FC<Props> = ({ heading, text }) => {
  const { lg } = useBreakpoint()

  return (
    <StyledContainer>
      <Wrapper>
        <TextColumn>
          <Heading
            as="h2"
            size={lg ? 38 : 26}
            sizeDiff={0}
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          <Text
            size={lg ? 18 : 16}
            dangerouslySetInnerHTML={{ __html: text }}
            margin={lg ? '0' : '26px'}
          />
        </TextColumn>
        <ButtonColumn>
          <Button as={Link} to="/drzwi-przesuwne/dodatki/">
            Zobacz dodatki
          </Button>
        </ButtonColumn>
      </Wrapper>
    </StyledContainer>
  )
}

export default Accessories
