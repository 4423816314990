import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightgray};
  padding: 40px 0 45px;

  ${media.lg.min} {
    padding: 144px 0 50px;
    margin-top: -64px;
  }
`

const ProfilesWrapper = styled.div`
  display: grid;
  gap: 10px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 146px);

  ${media.lg.min} {
    gap: 20px;
    grid-template-columns: repeat(auto-fit, 334px);
  }
`

const SingleProfile = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  height: 214px;
  display: flex;
  flex-direction: column;

  p {
    font-family: Merriweather;
    height: 45px;
  }

  ${media.lg.min} {
    height: 353px;

    p {
      height: 60px;
    }
  }
`

const ImageWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10px;
`

type Props = {
  heading: string
  profiles: Array<{
    color: string
    image: {
      src: ImageDataLike
      alt: string
    }
  }>
}

const Profiles: React.FC<Props> = ({ heading, profiles }) => {
  const { lg } = useBreakpoint()

  return (
    <Wrapper>
      <Container>
        <Heading
          as="h2"
          size={lg ? 38 : 22}
          sizeDiff={0}
          align="center"
          margin={lg ? '40px' : '30px'}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <ProfilesWrapper>
          {profiles?.map(({ color, image }) => (
            <SingleProfile>
              <ImageWrapper>
                <LazyImage
                  src={image?.src}
                  alt={image?.alt}
                  fill
                  objectFit="contain"
                />
              </ImageWrapper>
              <Text
                size={lg ? 24 : 16}
                weight={700}
                align="center"
                margin="0"
                dangerouslySetInnerHTML={{ __html: color }}
              />
            </SingleProfile>
          ))}
        </ProfilesWrapper>
      </Container>
    </Wrapper>
  )
}

export default Profiles
