import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

const Wrapper = styled.div`
  padding: 0 0 45px 0;

  ${media.lg.min} {
    padding: 0 0 100px 0;
  }
`

const StyledHeading = styled(Heading)`
  font-family: Merriweather;
`

const TechnicalDataWrapper = styled.div`
  display: grid;
  gap: 1px;
  margin-bottom: 60px;
  max-width: 1012px;

  ${media.lg.min} {
    margin-bottom: 80px;
  }
`

const TechnicalDataRow = styled.div`
  min-height: 60px;
  display: grid;
  grid-template-columns: 50% 50%;
  border: 1px solid ${({ theme }) => theme.colors.lightgray};

  ${media.lg.min} {
    grid-template-columns: 319px 1fr;
  }
`

const TechnicalDataCol = styled.div`
  &:first-child {
    background-color: ${({ theme }) => theme.colors.lightgray};
    font-weight: 700;
  }

  display: flex;
  align-items: center;

  font-size: 14px;
  font-family: Merriweather;
  padding: 12px;

  &:nth-child(2) {
    padding: 12px 22px;
  }

  ${media.lg.min} {
    font-size: 18px;
    padding: 21px;

    &:nth-child(2) {
      padding: 21px 30px;
    }
  }
`

const AccessoriesWrapper = styled.div`
  display: grid;
  gap: 1px;
  margin-bottom: 60px;
  max-width: 1012px;

  ${media.lg.min} {
    margin-bottom: 80px;
  }
`

const AccessoryRow = styled.div`
  background-color: ${({ theme }) => theme.colors.lightgray};
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Merriweather;
  padding: 12px;
  font-weight: 700;
  border: 1px solid ${({ theme }) => theme.colors.lightgray};

  ${media.lg.min} {
    font-size: 18px;
    padding: 21px;
  }
`

const StyledContainer = styled(Container)`
  ${media.lg.max} {
    padding: 0 0 0 36px;
  }
`

const TrackInfoWrapper = styled.div`
  margin-bottom: 20px;
  overflow-x: auto;
  max-width: 1012px;
`

const TrackInfoRow = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightgray};
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  min-width: 750px;

  &:first-child {
    background-color: ${({ theme }) => theme.colors.lightgray};
  }
`

const TrackInfoCol = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Merriweather;
  padding: 12px;
  font-weight: 700;

  ${media.lg.min} {
    font-size: 18px;
    padding: 21px;
  }
`

const Remarks = styled(Text)`
  font-family: Merriweather;
  max-width: 590px;
`

type Props = {
  specs: {
    technicalData: Array<{ parameter: string; value: string }>
    accessories: string[]
    track: {
      headings: string[]
      items: Array<string[]>
      remarks: string
    }
  }
}

const Specs: React.FC<Props> = ({
  specs: { technicalData, accessories, track },
}) => {
  const { lg } = useBreakpoint()

  return (
    <Wrapper>
      <Container>
        <StyledHeading
          as="p"
          size={lg ? 38 : 22}
          sizeDiff={0}
          margin={lg ? '40px' : '25px'}
        >
          Dane techniczne
        </StyledHeading>
        <TechnicalDataWrapper>
          {technicalData?.map(({ parameter, value }) => (
            <TechnicalDataRow>
              <TechnicalDataCol>{parameter}</TechnicalDataCol>
              <TechnicalDataCol>{value}</TechnicalDataCol>
            </TechnicalDataRow>
          ))}
        </TechnicalDataWrapper>
        <StyledHeading as="p" size={lg ? 30 : 22} sizeDiff={0} margin="25px">
          Akcesoria
        </StyledHeading>
        <AccessoriesWrapper>
          {accessories?.map((accessory) => (
            <AccessoryRow>{accessory}</AccessoryRow>
          ))}
        </AccessoriesWrapper>
        <StyledHeading as="p" size={lg ? 30 : 22} sizeDiff={0} margin="25px">
          Tor
        </StyledHeading>
      </Container>
      <StyledContainer>
        <TrackInfoWrapper>
          <TrackInfoRow>
            {track?.headings?.map((heading) => (
              <TrackInfoCol>{heading}</TrackInfoCol>
            ))}
          </TrackInfoRow>
          {track?.items?.map((item) => (
            <TrackInfoRow>
              {item?.map((elem) => (
                <TrackInfoCol>{elem}</TrackInfoCol>
              ))}
            </TrackInfoRow>
          ))}
        </TrackInfoWrapper>
      </StyledContainer>
      <Container>
        <Remarks
          size={lg ? 16 : 14}
          dangerouslySetInnerHTML={{ __html: track?.remarks }}
        />
      </Container>
    </Wrapper>
  )
}

export default Specs
