import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div`
  padding: 80px 0;

  ${media.lg.min} {
    padding: 120px 0;
  }

  p:first-of-type {
    font-family: Merriweather;
  }
`

const ModelsWrapper = styled.div`
  display: grid;
  gap: 45px;

  ${media.lg.min} {
    gap: 10px;
    grid-template-columns: repeat(3, min(calc(33.3% - 5px), 336px));
    justify-content: space-between;
  }
`

const SingleModel = styled.div`
  p {
    font-family: Merriweather;
    margin-bottom: 5px;
    ${media.lg.min} {
      margin-bottom: 10px;
    }
  }
`

const ImageWrapper = styled.div`
  margin-bottom: 15px;

  ${media.lg.min} {
    margin-bottom: 30px;
  }
`

type Props = {
  heading: string
  examples: Array<{
    image: {
      src: ImageDataLike
      alt: string
    }
    info: string
  }>
}

const Examples: React.FC<Props> = ({ heading, examples }) => {
  const { lg } = useBreakpoint()

  return (
    <Wrapper>
      <Container>
        <Heading
          as="p"
          size={lg ? 38 : 22}
          margin={lg ? '60px' : '25px'}
          sizeDiff={0}
          align="center"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <ModelsWrapper>
          {examples?.map(({ image, info }) => (
            <SingleModel>
              <ImageWrapper>
                <LazyImage src={image?.src} alt={image?.alt} />
              </ImageWrapper>
              <Text
                align={lg ? 'left' : 'center'}
                dangerouslySetInnerHTML={{ __html: info }}
              />
            </SingleModel>
          ))}
        </ModelsWrapper>
      </Container>
    </Wrapper>
  )
}

export default Examples
