import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div`
  padding: 80px 0 0;

  ${media.lg.min} {
    padding: 100px 0 0;
  }
`

const CategoriesWrapper = styled.div`
  display: grid;
  gap: 15px;

  ${media.lg.min} {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`

const CategoryWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.pewterblue};
  padding: 20px;

  ${media.lg.min} {
    padding: 25px 10%;
  }
`

const TypesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;

  ${media.lg.min} {
    grid-template-columns: repeat(auto-fit, 150px);
    justify-content: space-evenly;
  }
`

const SingleModel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ImageWrapper = styled.div`
  width: 80%;
  margin-bottom: 5px;

  ${media.lg.min} {
    margin-bottom: 8px;
  }
`

type Props = {
  heading: string
  models: Array<{
    category: string
    description?: string
    types: Array<{
      name: string
      image: {
        src: ImageDataLike
        alt: string
      }
    }>
  }>
}

const Models: React.FC<Props> = ({ heading, models }) => {
  const { lg } = useBreakpoint()

  return (
    <Wrapper>
      <Container>
        <Heading
          as="h2"
          size={lg ? 38 : 22}
          margin={lg ? '40px' : '35px'}
          sizeDiff={0}
          align="center"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <CategoriesWrapper>
          {models?.map((modelsGroup) => (
            <CategoryWrapper>
              <Text
                size={lg ? 30 : 20}
                weight={600}
                align="center"
                themecolor="white"
                margin={lg ? '30px' : '15px'}
                dangerouslySetInnerHTML={{ __html: modelsGroup?.category! }}
              />
              {modelsGroup?.description && (
                <Text
                  size={lg ? 18 : 16}
                  align="center"
                  weight={700}
                  themecolor="white"
                  dangerouslySetInnerHTML={{
                    __html: modelsGroup?.description!,
                  }}
                />
              )}
              <TypesWrapper>
                {modelsGroup?.types &&
                  modelsGroup?.types?.map((model) => (
                    <SingleModel>
                      <ImageWrapper>
                        <LazyImage
                          src={model?.image?.src}
                          alt={model?.image?.alt}
                        />
                      </ImageWrapper>
                      <Text
                        size={lg ? 16 : 14}
                        weight={600}
                        align="center"
                        themecolor="white"
                        dangerouslySetInnerHTML={{ __html: model?.name }}
                      />
                    </SingleModel>
                  ))}
              </TypesWrapper>
            </CategoryWrapper>
          ))}
        </CategoriesWrapper>
      </Container>
    </Wrapper>
  )
}

export default Models
